// src/DownloadSpoofer.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DownloadSpoofer = () => {
    const [key, setKey] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        const correctKey = '478bda3d-af1f-4806-994a-280fd2682941';
        const downloadUrl = 'https://download1325.mediafire.com/6gtn23ksnrogqu7G4h28H1tnt2yH3ZU5Oid7PoVsLT_4ydPnBVl8vHheFwwxzCtEo7BLFrfP5IxyQf4pm-xYpoyqcdDNJ-FCOOS-UtmGHZZh0nS2efHW9NiKAEswjqlCa2BdMDDJKEbXmNTiUKeRgSYtqBQ8eXn6xPdS9S6IMbXeYg/9mw4pkerxo2vpqb/e2d2144f9eea.exe';

        if (key === correctKey) {
            window.location.href = downloadUrl;
        } else {
            setError('Invalid key. Please try again.');
        }
    };

    return (
        <div className="download-spoofer">
            <h1>Download Spoofer</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Enter key"
                    value={key}
                    onChange={(e) => setKey(e.target.value)}
                />
                <button type="submit">Download</button>
            </form>
            {error && <p className="error">{error}</p>}
        </div>
    );
};

export default DownloadSpoofer;
