import React from 'react';
import './Deceit.css';

const Deceit = () => {
    return (
        <div className="deceit-container">
            <h1>DLL Loader Showcase</h1>
            <p>Explore the advanced features of our DLL loader designed for seamless integration and top-notch performance.</p>

            <div className="showcase">
                <div className="image-container">
                    <img src="https://i.ibb.co/9qB824L/dll-loader-interface.png" alt="DLL Loader Interface" />
                </div>
                <div className="image-container">
                    <img src="https://i.ibb.co/4tbdd3m/image.png" alt="DLL in Action" />
                </div>
            </div>

            <h2>Key Features</h2>
            <ul>
                <li><strong>Seamless Integration:</strong> Easily integrates with your applications, simplifying setup.</li>
                <li><strong>High Performance:</strong> Optimized for speed, enhancing your game efficiency.</li>
                <li><strong>Advanced Anti-Cheat Bypass:</strong> Cutting-edge technology to protect against detection.</li>
            </ul>

            <h2>Anti-Cheat Bypass Technology</h2>
            <p>
                Our DLL loader employs sophisticated bypass mechanisms, ensuring it remains undetectable and effective in competitive environments.
            </p>

            <h2>Why Choose Our Loader?</h2>
            <p>
                Designed for an optimal user experience, our DLL loader is intuitive, powerful, and reliable—ideal for developers and power users.
            </p>

            <h2 className="satisfaction">+35 Customers Satisfied</h2>

            <div className="contact-info">
                <p>For support, reach out to us on Discord: <strong>cheatsdeveloper</strong></p>
            </div>
        </div>
    );
};

export default Deceit;
