import React from 'react';
import './App.css'; // Ensure this file is imported to use the modal styles

const changelogEntries = [
    { date: '2023-03-28 11:12:45', update: 'Boosted Algorithms Precision - Improved aim assist algorithms for better accuracy at extreme angles.' },
    { date: '2024-07-19 09:03:30', update: 'Implemented Stealth Mode - Reduced in-game visibility for less detection risk during gameplay.' },
    { date: '2024-07-19 09:22:50', update: 'Added Cloak Engine - New feature that temporarily disguises player actions to appear as random anomalies.' },
    { date: '2024-07-19 09:45:12', update: 'Improved RNG Manipulator - More control over random number generation for a consistent advantage.' },
    { date: '2024-07-19 09:45:25', update: 'UI adjustments - Updated the control panel interface with a sleek new design and easier access to features.' },
    { date: '2024-07-19 09:45:55', update: 'Fixed minor bugs - Addressed issues reported by users and improved stability.' }
];

const ChangelogModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <button className="modal-close" onClick={onClose}>Close</button>
                <h2>Changelog</h2>
                <ul>
                    {changelogEntries.map((entry, index) => (
                        <li key={index}>
                            <div className="changelog-entry">
                                {entry.update}
                                <span className="changelog-date">{entry.date}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ChangelogModal;
