import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Removed Navigate import
import './App.css';
import ChangelogModal from './ChangelogModal'; // Import the new modal component
import DownloadSpoofer from './DownloadSpoofer'; // Import the new component
import Deceit from './Deceit'; // Import the Deceit component

function App() {
    const [raindrops, setRaindrops] = useState([]);
    const [lastUpdated] = useState(new Date('2024-07-19T11:47:21'));
    const [raindropLimit] = useState(20);
    const [notification, setNotification] = useState({ visible: false, message: '' });
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
    const [infoVisible, setInfoVisible] = useState(false); // State for INFO message visibility

    const handleClick = (message) => {
        setNotification({ visible: true, message });
        setTimeout(() => setNotification({ visible: false, message: '' }), 3500); // Hide after 3.5 seconds
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (raindrops.length < raindropLimit) {
                const newRaindrop = {
                    id: Date.now(),
                    left: Math.random() * window.innerWidth,
                    animationDuration: Math.random() * 4 + 2,
                };
                setRaindrops(prevRaindrops => [...prevRaindrops, newRaindrop]);
            }
        }, 750);

        return () => clearInterval(intervalId);
    }, [raindrops.length, raindropLimit]);

    const Raindrop = ({ left, animationDuration }) => {
        return (
            <div className="raindrop" style={{ left, animationDuration: `${animationDuration}s` }}>
                injecti0n
            </div>
        );
    };

    const formatTimeDifference = (date) => {
        const now = Date.now();
        const diff = now - date.getTime();

        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} ${hours % 24} hour${hours % 24 !== 1 ? 's' : ''} ${minutes % 60} minute${minutes % 60 !== 1 ? 's' : ''} ${seconds % 60 !== 0 ? `${seconds % 60} second${seconds % 60 !== 1 ? 's' : ''}` : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes % 60} minute${minutes % 60 !== 1 ? 's' : ''} ${seconds % 60 !== 0 ? `${seconds % 60} second${seconds % 60 !== 1 ? 's' : ''}` : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ${seconds % 60 !== 0 ? `${seconds % 60} second${seconds % 60 !== 1 ? 's' : ''}` : ''} ago`;
        } else if (seconds > 0) {
            return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
        } else {
            return 'Just now';
        }
    };

    const Clock = () => {
        const [time, setTime] = useState(new Date());

        useEffect(() => {
            const intervalId = setInterval(() => {
                setTime(new Date());
            }, 1000);

            return () => clearInterval(intervalId);
        }, []);

        const formatTime = (date) => {
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
        };

        return (
            <div className="clock">
                {formatTime(time)}
            </div>
        );
    };

    const Home = () => {
        return (
            <div className="App">
                <div id="main">
                    <div id="title">
                        <a href="/">shawzyy.com</a> {/* Updated href */}
                    </div>
                    <div id="subtitle">
                        closet cheating community<br />
                        designed for: LAN players, e-sports players and competitive players since 2017<br /><br />
                        <a href="/" onClick={() => handleClick("The slots are currently full")}>fc2 engine</a> updated {formatTimeDifference(lastUpdated)}<br />
                        <p style={{ color: '#00FF00', marginTop: '20px' }}>Protected by <a href="https://shield.shawzyy1337.workers.dev/" style={{ color: '#3755ff', textDecoration: 'none' }}>shield.shawzyy1337.workers.dev</a></p>
                    </div>
                    <Clock />
                    <UserArea handleClick={() => handleClick("You are not connected to the right proxy")} />
                </div>
                <button className="changelog-button" onClick={() => setIsModalOpen(true)}>Changelog</button> {/* Changelog button */}
                <button className="info-button" onClick={() => setInfoVisible(!infoVisible)}>INFO</button> {/* INFO button */}
                {infoVisible && <div className="info-message">Slots are currently full</div>} {/* INFO message */}
                {infoVisible && <div style={{marginTop: '50px' }} className="info-message">Referral closed</div>} {/* INFO message */}
            </div>
        );
    };

    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/downloadSpoofer" element={<DownloadSpoofer />} />
                    <Route path="/deceit" element={<Deceit />} />
                    <Route path="*" element={<HandleNotFound setNotification={setNotification} />} />
                </Routes>
            </Router>
            {notification.visible && <Notification message={notification.message} />}
            {raindrops.map(raindrop => (
                <Raindrop key={raindrop.id} left={raindrop.left} animationDuration={raindrop.animationDuration} />
            ))}
            <ChangelogModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} /> {/* Modal component */}
        </div>
    );
}

const Notification = ({ message }) => {
    return (
        <div className="notification">
            {message}
        </div>
    );
};

const HandleNotFound = ({ setNotification }) => {
    useEffect(() => {
        setNotification({ visible: true, message: 'You are not connected to the right proxy. Redirecting to home...' });
        const timer = setTimeout(() => {
            window.location.href = '/';
        }, 3000); // Redirect after 3 seconds

        return () => clearTimeout(timer);
    }, [setNotification]);

    return <div>Redirecting...</div>;
};

const UserArea = ({ handleClick }) => {
    return (
        <div className="user-area" onClick={handleClick}>
            User Area
        </div>
    );
};

export default App;
